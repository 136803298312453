.xsmPoppinsRegular {
  font-family: 'Poppins-Regular';
  font-size: 10px;
  line-height: 12px;

  @media screen and (max-width: 768px) {
    font-size: 8px;
  }

  @media screen and (max-width: 480px) {
    font-size: 8px;
  }
}

.smPoppinsRegular {
  font-family: 'Poppins-Regular';
  font-size: 14px;
  line-height: 16px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
}

.mdPoppinsRegular {
  font-family: 'Poppins-Regular';
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}

.lgPoppinsRegular {
  font-family: 'Poppins-Regular';
  font-size: 20px;
  line-height: 24px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 420px) {
    font-size: 16px;
  }
}

.xlPoppinsRegular {
  font-family: 'Poppins-Regular';
  font-size: 24px;
  line-height: 28px;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 420px) {
    font-size: 18px;
  }
}

.xxlPoppinsRegular {
  font-family: 'Poppins-Regular';
  font-size: 32px;
  line-height: 40px;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }

  @media screen and (max-width: 420px) {
    font-size: 24px;
  }
}

.xsmPoppinsMedium {
  font-family: 'Poppins-Medium';
  font-size: 10px;
  line-height: 12px;

  @media screen and (max-width: 768px) {
    font-size: 8px;
  }

  @media screen and (max-width: 480px) {
    font-size: 8px;
  }
}

.smPoppinsMedium {
  font-family: 'Poppins-Medium';
  font-size: 14px;
  line-height: 16px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
}

.mdPoppinsMedium {
  font-family: 'Poppins-Medium';
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}

.lgPoppinsMedium {
  font-family: 'Poppins-Medium';
  font-size: 20px;
  line-height: 24px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 420px) {
    font-size: 16px;
  }
}

.xlPoppinsMedium {
  font-family: 'Poppins-Medium';
  font-size: 24px;
  line-height: 28px;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 420px) {
    font-size: 18px;
  }
}

.xxlPoppinsMedium {
  font-family: 'Poppins-Medium';
  font-size: 32px;
  line-height: 40px;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }

  @media screen and (max-width: 420px) {
    font-size: 24px;
  }
}

.xsmPoppinsSemiBold {
  font-family: 'Poppins-SemiBold';
  font-size: 10px;
  line-height: 12px;

  @media screen and (max-width: 768px) {
    font-size: 8px;
  }

  @media screen and (max-width: 480px) {
    font-size: 8px;
  }
}

.smPoppinsSemiBold {
  font-family: 'Poppins-SemiBold';
  font-size: 14px;
  line-height: 16px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
}

.mdPoppinsSemiBold {
  font-family: 'Poppins-SemiBold';
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}

.lgPoppinsSemiBold {
  font-family: 'Poppins-SemiBold';
  font-size: 20px;
  line-height: 24px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 420px) {
    font-size: 16px;
  }
}

.xlPoppinsSemiBold {
  font-family: 'Poppins-SemiBold';
  font-size: 24px;
  line-height: 28px;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 420px) {
    font-size: 18px;
  }
}

.xxlPoppinsSemiBold {
  font-family: 'Poppins-SemiBold';
  font-size: 32px;
  line-height: 40px;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }

  @media screen and (max-width: 420px) {
    font-size: 24px;
  }
}

.xsmPoppinsBold {
  font-family: 'Poppins-Bold';
  font-size: 10px;
  line-height: 12px;

  @media screen and (max-width: 768px) {
    font-size: 8px;
  }

  @media screen and (max-width: 480px) {
    font-size: 8px;
  }
}

.smPoppinsBold {
  font-family: 'Poppins-Bold';
  font-size: 14px;
  line-height: 16px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }

  @media screen and (max-width: 480px) {
    font-size: 10px;
  }
}

.mdPoppinsBold {
  font-family: 'Poppins-Bold';
  font-size: 16px;
  line-height: 24px;

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }

  @media screen and (max-width: 480px) {
    font-size: 12px;
  }
}

.lgPoppinsBold {
  font-family: 'Poppins-Bold';
  font-size: 20px;
  line-height: 24px;

  @media screen and (max-width: 1024px) {
    font-size: 18px;
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
  }

  @media screen and (max-width: 420px) {
    font-size: 16px;
  }
}

.xlPoppinsBold {
  font-family: 'Poppins-Bold';
  font-size: 24px;
  line-height: 28px;

  @media screen and (max-width: 1024px) {
    font-size: 22px;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }

  @media screen and (max-width: 420px) {
    font-size: 18px;
  }
}

.xxlPoppinsBold {
  font-family: 'Poppins-Bold';
  font-size: 32px;
  line-height: 40px;

  @media screen and (max-width: 1024px) {
    font-size: 30px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
  }

  @media screen and (max-width: 420px) {
    font-size: 22px;
  }
}
