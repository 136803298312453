@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins-Regular';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Black';
  src: url('./fonts/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('./fonts/Poppins-Bold.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: 'Poppins-Regular', 'Poppins-Black', 'Poppins-Medium', 'Poppins-SemiBold',
    'Poppins-Bold';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #c3c4c5;
    border-radius: 100vh;
    border: 3px solid #ededed;
  }
}

.new-img {
  aspect-ratio: 2.4 / 1;
  object-fit: cover;
  width: 100%;
  height: auto;
}

.menu {
  opacity: 0;
  visibility: hidden;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
}

.menu.visible {
  opacity: 1;
  visibility: visible;
}

/* custom date picker input style */
.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
  outline: 2px solid #dfe1e4 !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  border-width: 0px !important;
  border-radius: 0.5rem !important;
}

.cm-date-picker .rs-picker-input-group {
  border-radius: 0.5rem !important;
  border-color: #dfe1e4 !important;
}

.cm-date-picker .rs-picker-input-group input {
  border-radius: 0.5rem !important;
}

.cm-date-picker .rs-input-group-addon {
  padding: 0 !important;
  padding-right: 10px !important;
}

/* custom number input style */
.rs-input-group:not(.rs-input-group-disabled):hover {
  outline: none;
}

.cm-number-input .rs-input-number {
  outline: 1px solid #dfe1e4 !important;
  border-radius: 0.5rem !important;
  border-width: 0px !important;
}

.cm-number-input .rs-input-number .rs-input {
  border-radius: 0.5rem !important;
}

/* custom style for dropdown icon style */
.cm-dropdown-icon .css-1tdl3m7-DropdownHandleComponent {
  margin: 0px 0px 2px 0px !important;
}

.cs-input-picker .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #333333;
}

/*custom style for multi dropdown*/
.cm-multi-select-dropdown .rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle {
  outline: 1px solid #dfe1e4 !important;
  border-radius: 0.5rem !important;
  border-width: 0px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.cm-multi-select-dropdown .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #333333 !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  outline: 2px solid #dfe1e4 !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
  border-width: 0px !important;
  border-radius: 0.5rem !important;
  border-color: transparent !;
}

.cm-table td,
.cm-table th {
  min-width: 180px;
}

.cm-table th:last-child,
.cm-table td:last-child {
  max-width: 100px;
  min-width: 100px;
}

.cm-table th:nth-child(2),
.cm-table td:nth-child(2) {
  max-width: 280px;
  min-width: 280px;
}

.cm-checkbox .rs-checkbox-control {
  left: 0 !important;
  top: 0 !important;
  right: 0 !important;
}

.cm-checkbox .rs-checkbox-checker {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-height: 0 !important;
}

.cm-reach .ql-container.ql-snow {
  border: 0;
}

.cm-reach .ql-toolbar.ql-snow {
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-color: #dfe1e4;
}
